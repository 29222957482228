// types
import type { FC, PropsWithChildren } from "react";
// lib
import { Toaster } from "sonner";

export const NotificationProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <>
      <Toaster
        position={"bottom-right"}
        closeButton
        richColors
        duration={10 * 60 * 1000}
        className="z-notification"
      />
      {children}
    </>
  );
};
